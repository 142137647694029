import * as types from './appLayout.types';

const initState = {
    margin:                     0,
    shouldAdjustBasedOnStories: false,
};

export function appLayoutReducer(state = initState, action) {
    const { type, margin = 0, status = false } = action;

    switch (type) {
            case types.APP_LAYOUT_SET_MARGIN: {
                return {
                    ...initState,
                    margin,
                };
            }

            case types.APP_LAYOUT_ADJUST_BASED_ON_STORIES: {
                return {
                    ...initState,
                    shouldAdjustBasedOnStories: status,
                };
            }

            default:
                return state;
    }
}
