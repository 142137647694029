import * as types from './partners.types';

const initState = {
    partners:          null,
    partnersError:     null,
    partnersIsLoading: false,

    partnersSingle:          null,
    partnersSingleError:     null,
    partnersSingleIsLoading: false,
};

// eslint-disable-next-line default-param-last
export function partnersReducer(state = initState, action) {
    const { type, ...rest } = action;

    switch (type) {
            case types.PARTNERS_GET_ALL_REQUEST: {
                return {
                    ...state,
                    partnersIsLoading: true,
                };
            }

            case types.PARTNERS_GET_ALL_SUCCESS: {
                return {
                    ...state,
                    partners:          rest.partners,
                    partnersError:     rest.error,
                    partnersIsLoading: false,
                };
            }

            case types.PARTNERS_GET_ALL_ERROR: {
                return {
                    ...state,
                    partners:          rest.partners,
                    partnersError:     rest.error,
                    partnersIsLoading: false,
                };
            }

            // partners single
            case types.PARTNERS_GET_SINGLE_REQUEST: {
                return {
                    ...state,
                    partnersSingleIsLoading: true,
                };
            }

            case types.PARTNERS_GET_SINGLE_SUCCESS: {
                return {
                    ...state,
                    partnersSingle:          rest.partnersSingle,
                    partnersSingleError:     rest.error,
                    partnersSingleIsLoading: false,
                };
            }

            case types.PARTNERS_GET_SINGLE_ERROR: {
                return {
                    ...state,
                    partnersSingle:          rest.partnersSingle,
                    partnersSingleError:     rest.error,
                    partnersSingleIsLoading: false,
                };
            }

            default:
                return state;
    }
}
