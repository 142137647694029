import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const availableTypes = ['default', 'info', 'success', 'warn', 'error'];
const defaultOptions = {
    position:        'top-center',
    autoClose:       5000,
    hideProgressBar: true,
    closeOnClick:    true,
    pauseOnHover:    true,
    draggable:       true,
    progress:        undefined,
    theme:           'dark',
    closeButton:     false,
    limit:           4,
    style:           {
        background:   '#171c26',
        borderRadius: '1rem',
    },
};

export default function useToast(options = {}) {
    /**
     *
     * @param {String} text
     * @param {'default' | 'info' | 'success' | 'warn' | 'error'} type - available types: default | info | success | warn | error
     */
    const triggerToast = (text, type = 'default') => {
        if (!availableTypes.includes(type)) {
            console.error(
                'toast type unknown, please use one of the available types: default | info | success | warn | error',
            );
        }

        switch (type) {
                case 'info':
                    customToast.info(text, options);

                    break;

                case 'success':
                    customToast.success(text, options);

                    break;

                case 'warn':
                    customToast.warn(text, options);

                    break;

                case 'error':
                    customToast.error(text, options);

                    break;

                default:
                    toast(text, options);

                    break;
        }
    };

    return { triggerToast };
}

export const customToast = {
    success(msg, options = {}) {
        return toast.success(msg, {
            ...defaultOptions,
            ...options,
            className: 'toast-success-container toast-success-container-after',
        });
    },
    error(msg, options = {}) {
        return toast.error(msg, {
            ...defaultOptions,
            ...options,
            className: 'toast-error-container toast-error-container-after',
        });
    },
    warn(msg, options = {}) {
        return toast.warn(msg, {
            ...defaultOptions,
            ...options,
            className: 'toast-warn-container toast-error-container-after',
        });
    },
    info(msg, options = {}) {
        return toast.info(msg, {
            ...defaultOptions,
            ...options,
            className: 'toast-info-container toast-info-container-after',
        });
    },
};
