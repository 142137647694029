/**
 *
 * @param {{ bytes: Number | String, decimals: Number, showUnit: Boolean }} options
 * @returns
 */
export const formatBytes = ({ bytes, decimals = 2, showUnit = false }) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const formattedBytes = parseFloat((bytes / k ** i).toFixed(dm));

    const result = showUnit ? `${ formattedBytes } ${ sizes[i] }` : formattedBytes;

    return result;
};

export const milliSecondsToSeconds = (seconds) => seconds * 1000;
export const milliSecondsToMinutes = (minutes) => minutes * 60000;

export function capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
}
