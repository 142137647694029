import * as types from './updateHandler.types';

const initState = {
    shouldUpdateStore: false,
};

export function updateHandlerReducer(state = initState, action) {
    const { type, ...rest } = action;

    switch (type) {
    case types.UPDATE_HANDLER_TRIGGER_STORE_UPDATE: {
        return {
            ...state,
            shouldUpdateStore: rest.shouldUpdateStore,
        };
    }

    default:
        return state;
    }
}
