export const SHOP_GET_ALL_REQUEST = 'SHOP_GET_ALL_REQUEST';
export const SHOP_GET_ALL_SUCCESS = 'SHOP_GET_ALL_SUCCESS';
export const SHOP_GET_ALL_ERROR = 'SHOP_GET_ALL_ERROR';

export const SHOP_GET_SINGLE_REQUEST = 'SHOP_GET_SINGLE_REQUEST';
export const SHOP_GET_SINGLE_SUCCESS = 'SHOP_GET_SINGLE_SUCCESS';
export const SHOP_GET_SINGLE_ERROR = 'SHOP_GET_SINGLE_ERROR';

export const SHOP_PURCHASE_REQUEST = 'SHOP_PURCHASE_REQUEST';
export const SHOP_PURCHASE_SUCCESS = 'SHOP_PURCHASE_SUCCESS';
export const SHOP_PURCHASE_ERROR = 'SHOP_PURCHASE_ERROR';

export const SHOP_PURCHASE_SINGLE_REQUEST = 'SHOP_PURCHASE_SINGLE_REQUEST';
export const SHOP_PURCHASE_SINGLE_SUCCESS = 'SHOP_PURCHASE_SINGLE_SUCCESS';
export const SHOP_PURCHASE_SINGLE_ERROR = 'SHOP_PURCHASE_SINGLE_ERROR';
