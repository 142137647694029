import * as t from './types';

const initState = {
    email:    null,
    password: null,
    otp:      null,
};

export default (state = initState, action) => {
    switch (action.type) {
            case t.SET_EMAIL_PHONE:
                return { ...state, email: action.payload };

            case t.SET_PASSWORD:
                return { ...state, password: action.payload };

            case t.SET_OTP:
                return { ...state, otp: action.payload };

            case t.CLEAR_PROCESS:
                return initState;

            default:
                return state;
    }
};
