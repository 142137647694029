export const All_PATHS_NAMESPACES = {
    ar: {
        maintenance: {
            name: 'maintenance',
            path: '/ar/maintenance',
        },
        instruction: {
            name: 'welcome',
            path: '/ar/welcome',
        },
        login: {
            name: 'sign-in',
            path: '/ar/sign-in',
        },
        loginOrange: {
            name: 'orange',
            path: '/ar/orange',
        },
        sign_up: {
            name: 'sign-up',
            path: '/ar/sign-up',
        },
        sign_up_check_pin: {
            name: 'check',
            path: '/ar/sign-up/check',
        },
        reset_password: {
            name: 'reset-password',
            path: '/ar/reset-password',
        },
        reset_password_check_otp: {
            name: 'check',
            path: '/ar/reset-password/check',
        },
        reset_password_new_password: {
            name: 'new-password',
            path: '/ar/reset-password/new-password',
        },
        fortuneWheel: {
            name: 'fortune-wheel',
            path: '/ar/fortune-wheel',
        },
        home: {
            name: '/',
            path: '/ar/',
        },
        questions: {
            name: 'questions',
            path: '/ar/questions',
        },
        story: {
            name: 'story',
            path: '/ar/story',
        },
        pin: {
            name: 'pin',
            path: '/ar/pin',
        },
        onboarding: {
            name: 'onboarding',
            path: '/ar/onboarding',
        },
        store: {
            name: 'store',
            path: '/ar/store',
        },
        store_single: {
            name: ':store',
            path: '/ar/store/:store',
        },
        wallet: {
            name: 'wallet',
            path: '/ar/wallet',
        },
        expired_wallet: {
            name: 'expired',
            path: '/ar/wallet/expired',
        },
        referrals: {
            name: 'referrals',
            path: '/ar/profile/referrals',
        },
        profile: {
            name: 'profile',
            path: '/ar/profile',
        },
        edit_profile: {
            name: 'edit',
            path: '/ar/profile/edit',
        },
        edit_tags: {
            name: 'edit-tags',
            path: '/ar/profile/edit-tags',
        },
        levels: {
            name: 'levels',
            path: '/ar/profile/levels',
        },
        order_history: {
            name: 'order-history',
            path: '/ar/profile/order-history',
        },
        'help-and-contact': {
            name: 'help-and-contact',
            path: '/ar/profile/help-and-contact',
        },
        'help-center': {
            name: 'help-center',
            path: '/ar/help-center',
        },
        points_history: {
            name: 'points-history',
            path: '/ar/points-history',
        },
        'wallet-voucher-details': {
            name: 'voucher/:id',
            path: '/ar/wallet/voucher/:id',
        },
        'wallet-voucher-details-history': {
            name: 'voucher/:id/history',
            path: '/ar/wallet/voucher/:id/history',
        },
        'voucher-details': {
            name: 'voucher/:id',
            path: '/ar/voucher/:id',
        },
        scan: {
            name: 'scan',
            path: '/ar/scan',
        },
        'scan-id': {
            name: ':id',
            path: '/ar/scan/:id',
        },
        'privacy-policy': {
            name: 'privacy-policy',
            path: '/ar/privacy-policy',
        },
    },
    fr: {
        maintenance: {
            name: 'maintenance',
            path: '/fr/maintenance',
        },
        instruction: {
            name: 'welcome',
            path: '/fr/welcome',
        },
        login: {
            name: 'sign-in',
            path: '/fr/sign-in',
        },
        loginOrange: {
            name: 'orange',
            path: '/fr/orange',
        },
        sign_up: {
            name: 'sign-up',
            path: '/fr/sign-up',
        },
        sign_up_check_pin: {
            name: 'check',
            path: '/fr/sign-up/check',
        },
        reset_password: {
            name: 'reset-password',
            path: '/fr/reset-password',
        },
        reset_password_check_otp: {
            name: 'check',
            path: '/fr/reset-password/check',
        },
        reset_password_new_password: {
            name: 'new-password',
            path: '/fr/reset-password/new-password',
        },
        fortuneWheel: {
            name: 'fortune-wheel',
            path: '/fr/fortune-wheel',
        },
        home: {
            name: '/',
            path: '/fr/',
        },
        questions: {
            name: 'questions',
            path: '/fr/questions',
        },
        story: {
            name: 'story',
            path: '/fr/story',
        },
        pin: {
            name: 'pin',
            path: '/fr/pin',
        },
        onboarding: {
            name: 'onboarding',
            path: '/fr/onboarding',
        },
        store: {
            name: 'store',
            path: '/fr/store',
        },
        store_single: {
            name: ':store',
            path: '/fr/store/:store',
        },
        wallet: {
            name: 'wallet',
            path: '/fr/wallet',
        },
        expired_wallet: {
            name: 'expired',
            path: '/fr/wallet/expired',
        },
        referrals: {
            name: 'referrals',
            path: '/fr/profile/referrals',
        },
        profile: {
            name: 'profile',
            path: '/fr/profile',
        },
        edit_profile: {
            name: 'edit',
            path: '/fr/profile/edit',
        },
        edit_tags: {
            name: 'edit-tags',
            path: '/fr/profile/edit-tags',
        },
        levels: {
            name: 'levels',
            path: '/fr/profile/levels',
        },
        order_history: {
            name: 'order-history',
            path: '/fr/profile/order-history',
        },
        'help-and-contact': {
            name: 'help-and-contact',
            path: '/fr/profile/help-and-contact',
        },
        'help-center': {
            name: 'help-center',
            path: '/fr/help-center',
        },
        points_history: {
            name: 'points-history',
            path: '/fr/points-history',
        },
        'wallet-voucher-details': {
            name: 'voucher/:id',
            path: '/fr/wallet/voucher/:id',
        },
        'wallet-voucher-details-history': {
            name: 'voucher/:id/history',
            path: '/fr/wallet/voucher/:id/history',
        },
        'voucher-details': {
            name: 'voucher/:id',
            path: '/fr/voucher/:id',
        },
        scan: {
            name: 'scan',
            path: '/fr/scan',
        },
        'scan-id': {
            name: ':id',
            path: '/fr/scan/:id',
        },
        'privacy-policy': {
            name: 'privacy-policy',
            path: '/fr/privacy-policy',
        },
    },
    en: {
        maintenance: {
            name: 'maintenance',
            path: '/en/maintenance',
        },
        instruction: {
            name: 'welcome',
            path: '/en/welcome',
        },
        login: {
            name: 'sign-in',
            path: '/en/sign-in',
        },
        loginOrange: {
            name: 'orange',
            path: '/en/orange',
        },
        sign_up: {
            name: 'sign-up',
            path: '/en/sign-up',
        },
        sign_up_check_pin: {
            name: 'check',
            path: '/en/sign-up/check',
        },
        reset_password: {
            name: 'reset-password',
            path: '/en/reset-password',
        },
        reset_password_check_otp: {
            name: 'check',
            path: '/en/reset-password/check',
        },
        reset_password_new_password: {
            name: 'new-password',
            path: '/en/reset-password/new-password',
        },
        fortuneWheel: {
            name: 'fortune-wheel',
            path: '/en/fortune-wheel',
        },
        home: {
            name: '/',
            path: '/en/',
        },
        questions: {
            name: 'questions',
            path: '/en/questions',
        },
        story: {
            name: 'story',
            path: '/en/story',
        },
        pin: {
            name: 'pin',
            path: '/en/pin',
        },
        onboarding: {
            name: 'onboarding',
            path: '/en/onboarding',
        },
        store: {
            name: 'store',
            path: '/en/store',
        },
        store_single: {
            name: ':store',
            path: '/en/store/:store',
        },
        wallet: {
            name: 'wallet',
            path: '/en/wallet',
        },
        expired_wallet: {
            name: 'expired',
            path: '/en/wallet/expired',
        },
        referrals: {
            name: 'referrals',
            path: '/en/profile/referrals',
        },
        profile: {
            name: 'profile',
            path: '/en/profile',
        },
        edit_profile: {
            name: 'edit',
            path: '/en/profile/edit',
        },
        edit_tags: {
            name: 'edit-tags',
            path: '/en/profile/edit-tags',
        },
        levels: {
            name: 'levels',
            path: '/en/profile/levels',
        },
        order_history: {
            name: 'order-history',
            path: '/en/profile/order-history',
        },
        'help-and-contact': {
            name: 'help-and-contact',
            path: '/en/profile/help-and-contact',
        },
        'help-center': {
            name: 'help-center',
            path: '/en/help-center',
        },
        points_history: {
            name: 'points-history',
            path: '/en/points-history',
        },
        'wallet-voucher-details': {
            name: 'voucher/:id',
            path: '/en/wallet/voucher/:id',
        },
        'wallet-voucher-details-history': {
            name: 'voucher/:id/history',
            path: '/en/wallet/voucher/:id/history',
        },
        'voucher-details': {
            name: 'voucher/:id',
            path: '/en/voucher/:id',
        },
        scan: {
            name: 'scan',
            path: '/en/scan',
        },
        'scan-id': {
            name: ':id',
            path: '/en/scan/:id',
        },
        'privacy-policy': {
            name: 'privacy-policy',
            path: '/en/privacy-policy',
        },
    },
};
