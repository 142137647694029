import * as types from './shop.types';

const initState = {
    items:          null,
    itemsError:     null,
    itemsIsLoading: false,

    shopSingle:          null,
    shopSingleError:     null,
    shopSingleIsLoading: false,
};

export function shopReducer(state = initState, action) {
    const { type, ...rest } = action;

    switch (type) {
        // shop items
            case types.SHOP_GET_ALL_REQUEST: {
                return {
                    ...state,
                    itemsIsLoading: true,
                };
            }

            case types.SHOP_GET_ALL_SUCCESS: {
                return {
                    ...state,
                    items:          action.items,
                    itemsError:     rest.error,
                    itemsIsLoading: false,
                };
            }

            case types.SHOP_GET_ALL_ERROR: {
                return {
                    ...state,
                    items:          rest.items,
                    itemsError:     rest.error,
                    itemsIsLoading: false,
                };
            }

            // shop single
            case types.SHOP_GET_SINGLE_REQUEST: {
                return {
                    ...state,
                    shopSingleIsLoading: true,
                };
            }

            case types.SHOP_GET_SINGLE_SUCCESS: {
                return {
                    ...state,
                    shopSingle:          action.data,
                    shopSingleError:     rest.error,
                    shopSingleIsLoading: false,
                };
            }

            case types.SHOP_GET_SINGLE_ERROR: {
                return {
                    ...state,
                    shopSingle:          rest.data,
                    shopSingleError:     rest.error,
                    shopSingleIsLoading: false,
                };
            }

            default:
                return state;
    }
}
