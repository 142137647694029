import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

// any SEO related tags will be inside the <Helmet></Helmet> tag
// alongside the title.
// https://github.com/nfl/react-helmet

interface TAOOHelmetProps {
    title?: string;
    children?: ReactNode;
}

const BasePageTitle = 'TAOO |';

const TAOOHelmet = ({ title, children }: TAOOHelmetProps) => (
    <Helmet>
        <title>{`${ BasePageTitle } ${ title }`}</title>
        {children}
    </Helmet>
);

TAOOHelmet.defaultProps = {
    title:    '',
    children: undefined,
};

export default TAOOHelmet;
