import * as t from './types';

const initState = {
    currentStep:   0,
    isLoading:     false,
    showMessage:   false,
    remeberedStep: false,
    totalSteps:    3,
    later:         false,
};

export default (state = initState, action) => {
    switch (action.type) {
            case t.SET_CURRENT_STEP:
                return { ...state, currentStep: action.payload };

            case t.SET_LATER:
                return { ...state, later: true };

            case t.ONBOARDING_RESUME:
                return { ...state, later: false };

            case t.SET_NEXT_STEP:
                return {
                    ...state,
                    currentStep:   state.currentStep + 1,
                    showMessage:   false,
                    remeberedStep: false,
                };

            case t.SET_NEXT:
                return { ...state, showMessage: true, remeberedStep: action.payload };

            case t.CLEAR_STEP_PROCESS:
                return {
                    ...state,
                    later:         false,
                    showMessage:   false,
                    remeberedStep: false,
                };

            default:
                return state;
    }
};
