/**
 *
 * @param {String} storageKey
 * @returns
 */
export function getStorageItem(storageKey) {
    let result = null;

    try {
        const res = localStorage.getItem(storageKey);

        if (res != null) {
            result = JSON.parse(res);
        }
    } catch (error) {
        console.error(error);
    }

    return result;
}

/**
 *
 * @param {String} storageKey
 * @param {*} storageValue
 * @returns
 */
export function setStorageItem(storageKey, storageValue) {
    try {
        localStorage.setItem(storageKey, JSON.stringify(storageValue));
    } catch (error) {
        console.error(error);
    }

    return storageValue;
}

/**
 *
 * @param {String} storageKey
 */
export const deleteStorageItem = (storageKey) => {
    try {
        localStorage.removeItem(storageKey);
    } catch (error) {
        console.error(error);
    }
};
