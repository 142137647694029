export const UnknownValue = 'N/A';

export const MaxUploadSize = 2000; // in megabytes(MB)

export const LoaderInButtonFillColor = '#000';

export const PurchaseTypes = {
    moneyOnly:      'MONEY_ONLY',
    pointsOnly:     'POINTS_ONLY',
    moneyAndPoints: 'MONEY_AND_POINTS',
};

export const PointsHistoryStatus = {
    customerValidationNeeded: 'CUSTOMER_VALIDATION_NEEDED',
    taooValidationNeeded:     'TAOO_VALIDATION_NEEDED',
    claimed:                  'CLAIMED',
    pending:                  'PENDING',
    refused:                  'REFUSED',
};
