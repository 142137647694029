export const levelStepProgressBarStyles = {
    path: {
        stroke:          '#32BA7C',
        strokeLinecap:   'round',
        transition:      'stroke-dashoffset 0.5s ease 0s',
        transformOrigin: 'center center',
    },
    trail: {
        stroke:          'rgba(55, 64, 83, 1)',
        strokeWidth:     5,
        strokeLinecap:   'butt',
        transformOrigin: 'center center',
    },
};

export const progressBarStyles = {
    path: {
        stroke:          `#F0C514`,
        strokeLinecap:   'butt',
        transition:      'stroke-dashoffset 0.5s ease 0s',
        transformOrigin: 'center center',
    },
    trail: {
        stroke:          '#171C26',
        strokeLinecap:   'butt',
        transformOrigin: 'center center',
    },
};

export const levelProgressBarStyles = {
    path: {
        stroke:        `#F0C514`,
        strokeLinecap: 'round',
        transition:    'stroke-dashoffset 0.5s ease 0s',

        transformOrigin: 'center center',
    },
    trail: {
        stroke:        'rgba(55, 64, 83, 1)',
        strokeWidth:   5,
        strokeLinecap: 'butt',

        transformOrigin: 'center center',
    },
};
