import * as t from './types';

export const setEmailPhone =
    (email_phone = '') => (dispatch) => {
        dispatch({
            type:    t.SET_EMAIL_PHONE,
            payload: email_phone,
        });
    };

export const setPassword =
    (password = '') => (dispatch) => {
        dispatch({
            type:    t.SET_PASSWORD,
            payload: password,
        });
    };

export const setOtp =
    (otp = '') => (dispatch) => {
        dispatch({
            type:    t.SET_OTP,
            payload: otp,
        });
    };

export const clearLoginProcess = () => (dispatch) => {
    dispatch({
        type: t.SET_EMAIL_PHONE,
    });
};
