import * as types from './levels.types';

const initState = {
    data:      null,
    error:     null,
    isLoading: false,
};

export function levelsReducer(state = initState, action) {
    const { type, error, data } = action;

    switch (type) {
            case types.LEVELS_GET_ALL_REQUEST: {
                return {
                    ...state,
                    isLoading: true,
                };
            }

            case types.LEVELS_GET_ALL_ERROR: {
                return {
                    ...state,
                    error,
                    data,
                    isLoading: false,
                };
            }

            case types.LEVELS_GET_ALL_SUCCESS: {
                return {
                    ...state,
                    data,
                    error:     null,
                    isLoading: false,
                };
            }

            default:
                return state;
    }
}
