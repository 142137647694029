export const WALLET_GET_ALL_REQUEST = 'WALLET_GET_ALL_REQUEST';
export const WALLET_GET_ALL_SUCCESS = 'WALLET_GET_ALL_SUCCESS';
export const WALLET_GET_ALL_ERROR = 'WALLET_GET_ALL_ERROR';

export const WALLET_GET_TRANSACTION_HISTORY_REQUEST = 'WALLET_GET_TRANSACTION_HISTORY_REQUEST';
export const WALLET_GET_TRANSACTION_HISTORY_SUCCESS = 'WALLET_GET_TRANSACTION_HISTORY_SUCCESS';
export const WALLET_GET_TRANSACTION_HISTORY_ERROR = 'WALLET_GET_TRANSACTION_HISTORY_ERROR';

export const WALLET_GET_EXPIRED_REQUEST = 'WALLET_GET_EXPIRED_REQUEST';
export const WALLET_GET_EXPIRED_SUCCESS = 'WALLET_GET_EXPIRED_SUCCESS';
export const WALLET_GET_EXPIRED_ERROR = 'WALLET_GET_EXPIRED_ERROR';

export const WALLET_GET_WALLET_ITEM_HISTORY_REQUEST = 'WALLET_GET_WALLET_ITEM_HISTORY_REQUEST';
export const WALLET_GET_WALLET_ITEM_HISTORY_SUCCESS = 'WALLET_GET_WALLET_ITEM_HISTORY_SUCCESS';
export const WALLET_GET_WALLET_ITEM_HISTORY_ERROR = 'WALLET_GET_WALLET_ITEM_HISTORY_ERROR';
