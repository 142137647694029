import * as types from './profile.types';

const initState = {
    data:          null,
    dataIsLoading: false,
    dataError:     null,

    info:          null,
    InfoIsLoading: false,
    infoError:     null,

    isUpdated:           false,
    infoUpdateIsLoading: false,
    infoUpdateError:     null,

    isAvatarUpdated:       false,
    avatarUpdateIsLoading: false,
    avatarUpdateError:     null,

    error:     null,
    isLoading: false,
};

export function profileReducer(state = initState, action) {
    const { type, ...rest } = action;

    switch (type) {
        // profile
            case types.PROFILE_GET_REQUEST: {
                return {
                    ...state,
                    dataIsLoading: true,
                };
            }

            case types.PROFILE_GET_SUCCESS: {
                return {
                    ...state,
                    data:          action.data,
                    dataError:     rest.error,
                    dataIsLoading: false,
                };
            }

            case types.PROFILE_GET_ERROR: {
                return {
                    ...state,
                    data:          rest.data,
                    dataError:     rest.error,
                    dataIsLoading: false,
                };
            }

            // profile edit info
            case types.PROFILE_GET_INFO_REQUEST: {
                return {
                    ...state,
                    InfoIsLoading: true,
                };
            }

            case types.PROFILE_GET_INFO_SUCCESS: {
                return {
                    ...state,
                    info:          rest.info,
                    infoError:     rest.error,
                    InfoIsLoading: false,

                    // update reset
                    isUpdated:       false,
                    isAvatarUpdated: false,
                };
            }

            case types.PROFILE_GET_INFO_ERROR: {
                return {
                    ...state,
                    info:          rest.info,
                    infoError:     rest.error,
                    InfoIsLoading: false,
                };
            }

            // profile edit
            case types.PROFILE_UPDATE_REQUEST: {
                return {
                    ...state,
                    infoUpdateIsLoading: true,
                };
            }

            case types.PROFILE_UPDATE_SUCCESS: {
                return {
                    ...state,
                    isUpdated:           true,
                    infoUpdateError:     rest.error,
                    infoUpdateIsLoading: false,
                };
            }

            case types.PROFILE_UPDATE_ERROR: {
                return {
                    ...state,
                    isUpdated:           false,
                    infoUpdateError:     rest.error,
                    infoUpdateIsLoading: false,
                };
            }

            // profile avatar update
            case types.PROFILE_UPDATE_AVATAR_REQUEST: {
                return {
                    ...state,
                    infoUpdateIsLoading: true,
                };
            }

            case types.PROFILE_UPDATE_AVATAR_SUCCESS: {
                return {
                    ...state,
                    isAvatarUpdated:       true,
                    avatarUpdateError:     rest.error,
                    avatarUpdateIsLoading: false,
                };
            }

            case types.PROFILE_UPDATE_AVATAR_ERROR: {
                return {
                    ...state,
                    isAvatarUpdated:       false,
                    avatarUpdateError:     rest.error,
                    avatarUpdateIsLoading: false,
                };
            }

            default:
                return state;
    }
}
