import classNames from 'classnames';
import { RE_DIGIT } from 'constants';
import React, { useMemo } from 'react';

function OtpInput({ value, valueLength, onChange, hasError = false }) {
    const valueItems = useMemo(
        () => {
            const valueArray = value.split('');
            const items = [];

            for (let i = 0; i < valueLength; i++) {
                const char = valueArray[i];

                if (RE_DIGIT.test(char)) {
                    items.push(char);
                } else {
                    items.push('');
                }
            }

            return items;
        }, [value, valueLength],
    );

    const focusToNextInput = (target) => {
        const { nextElementSibling } = target;

        if (nextElementSibling) {
            nextElementSibling.focus();
        }
    };

    const focusToPrevInput = (target) => {
        const { previousElementSibling } = target;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnChange = (
        e,
        idx,
    ) => {
        const { target } = e;
        let targetValue = target.value.trim();
        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        const nextInputEl = target.nextElementSibling;

        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue =
            value.substring(
                0, idx,
            ) + targetValue + value.substring(idx + 1);

            onChange(newValue);

            if (!isTargetValueDigit) {
                return;
            }

            focusToNextInput(target);
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);

            target.blur();
        }
    };

    const inputOnKeyDown = (e) => {
        const { key } = e;
        const { target } = e;

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault();

            return focusToNextInput(target);
        }

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault();

            return focusToPrevInput(target);
        }

        const targetValue = target.value;

        target.setSelectionRange(
            0, targetValue.length,
        );

        if (e.key !== 'Backspace' || targetValue !== '') {
            return;
        }

        focusToPrevInput(target);
    };

    const inputOnFocus = (e) => {
        const { target } = e;
        const prevInputEl =
          target.previousElementSibling;

        if (prevInputEl && prevInputEl.value === '') {
            return prevInputEl.focus();
        }

        target.setSelectionRange(
            0, target.value.length,
        );
    };

    return (
        <div className="otp-group">
            {valueItems.map((
                digit, idx,
            ) => (
                <React.Fragment key={idx}>
                    <input

                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        pattern="\d{1}"
                        maxLength={valueLength}
                        className={classNames("otp-input", { hasError })}
                        value={digit}
                        onChange={(e) => inputOnChange(
                            e, idx,
                        )}
                        onKeyDown={inputOnKeyDown}
                        onFocus={inputOnFocus}
                    />
                </React.Fragment>
            ))}
        </div>
    );
}


export default OtpInput;
