import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import CheckPin from 'components/check-pin';
import TAOOHelmet from 'components/helmet/Helmet';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

function CheckInscription({ lang = 'fr' }) {
    const intl = useIntl();

    return (
        <PageTransitionWrapper>
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.check-inscription' })} />
            <CheckPin path={All_PATHS_NAMESPACES[lang].home.path} />
        </PageTransitionWrapper>
    );
}

const mapStateToProps = (state) => ({
    lang: state.locale,
});

export default connect(mapStateToProps, null)(CheckInscription);
