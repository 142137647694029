import { LazyMotion, m } from 'framer-motion';
import { AnimationEventHandler, CSSProperties, ReactNode } from 'react';

interface PageTransitionWrapperProps {
    onAnimationComplete?: (definition: any) => void;
    onAnimationStart?: (definition: any) => void;
    onAnimationEnd?: AnimationEventHandler<any> | undefined;
    children?: ReactNode;
    style?: CSSProperties;
    className?: string;
}

const PageTransitionVariants = {
    hidden: {
        opacity: 0,
        y:       -30,
    },
    visible: {
        opacity: 1,
        y:       0,
    },
    exit: {
        opacity:    0,
        y:          -30,
        transition: {
            duration: 0.15,
        },
    },
};

const loadFeatures = () => import('services/framer-motion/domAnimation').then((res) => res.default);

function PageTransitionWrapper({
    onAnimationComplete,
    onAnimationStart,
    onAnimationEnd,
    children,
    style,
    className,
}: PageTransitionWrapperProps) {
    return (
        <LazyMotion features={loadFeatures}>
            <m.main
                onAnimationComplete={onAnimationComplete}
                onAnimationStart={onAnimationStart}
                onAnimationEnd={onAnimationEnd}
                variants={PageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={className}
                style={style}
            >
                {children}
            </m.main>
        </LazyMotion>
    );
}

PageTransitionWrapper.defaultProps = {
    onAnimationComplete: undefined,
    onAnimationStart:    undefined,
    onAnimationEnd:      undefined,
    children:            undefined,
    style:               undefined,
    className:           undefined,
};

export default PageTransitionWrapper;
