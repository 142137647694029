import { LOCALES } from './locale';
import translations from '../../translations.json';

interface LocaleToLanguageMapper {
    [language: string]: {
        [translation: string]: string;
    };
}

export const localeToLanguageMapper = {
    [LOCALES.ARABIC]:  translations.arabic,
    [LOCALES.FRENCH]:  translations.french,
    [LOCALES.ENGLISH]: translations.english,
} satisfies LocaleToLanguageMapper;

export { LOCALES } from './locale';
