export { levelProgressBarStyles, levelStepProgressBarStyles, progressBarStyles } from './progress';
export { introTooltipsKeys } from './introTooltips';

export const spring = {
    type:      'spring',
    stiffness: 700,
    damping:   50,
};

export const switchMessageStep = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity:    1,
        transition: {
            duration: 0.5,
        },
    },
    exit: {
        opacity:    0,
        transition: {
            duration: 0.5,
        },
    },
};

export const fadeProps = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity:    1,
        transition: {
            duration: 0.5,
        },
    },
    exit: {
        opacity:    0,
        transition: {
            duration: 1,
        },
    },
};

export const popupVariant = {
    initial: {
        opacity: 0,
        y:       50,
    },
    animate: {
        opacity:    1,
        y:          0,
        transition: {
            duration: 0.5,
        },
    },
    exit: {
        opacity:    0,
        y:          50,
        transition: {
            duration: 1,
        },
    },
};

export const ErrorMessages = {
    auth_process: {
        USER_AUTH_FAILED:             'USER_AUTH_FAILED',
        USER_EXIST:                   'USER_EXIST',
        USER_INVALID_PASSWORD_FORMAT: 'USER_INVALID_PASSWORD_FORMAT',
        USER_UNVERIFIED:              'USER_UNVERIFIED',
        USER_SINGLE_SIGN_IN:              'USER_SINGLE_SIGN_IN',
        USER_SUBSCRIPTION_NOT_FOUND: 'USER_SUBSCRIPTION_NOT_FOUND',
        USER_SUBSCRIPTION_EXPIRED: 'USER_SUBSCRIPTION_EXPIRED',
    },
};

export const RE_DIGIT = new RegExp(/^\d+$/);
