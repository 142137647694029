import { combineReducers } from 'redux';

import { appLayoutReducer } from './app-layout/appLayout.reducer';
import { levelsReducer } from './levels/levels.reducer';
import { liveReducer } from './live/live.reducer';
import locale from './locale';
import loginProcess from './login-process';
import { partnersReducer } from './partners/partners.reducer';
import points from './points';
import { profileReducer } from './profile/profile.reducer';
import { referralReducer } from './referral/referral.reducer';
import { shopReducer } from './shop/shop.reducer';
import steps from './step-process';
import { tagReducer } from './tag/tag.reducer';
import { updateHandlerReducer } from './update-handler/updateHandler.reducer';
import user from './user';
import { walletReducer } from './wallet/wallet.reducer';

const appReducer = combineReducers({
    updateHandlerReducer,

    liveReducer,
    levelsReducer,
    referralReducer,
    profileReducer,
    walletReducer,
    shopReducer,
    partnersReducer,
    tagReducer,
    locale,
    user,
    loginProcess,
    steps,
    points,
    appLayoutReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'UPDATE_HANDLER_TRIGGER_STORE_UPDATE' && state.updateHandlerReducer.shouldUpdateStore) {
        // console.log(`[rootReducer](UPDATE_HANDLER: ${ new Date() }): updating reducers...`);

        const reducersToUpdate = [
            // 'tagReducer',
            // 'walletReducer',
            // 'shopReducer',
            // 'referralReducer',
            // 'profileReducer',
            // 'levelsReducer'
        ];

        const reducers = appReducer(state, action);

        const updatedReducers = Object.fromEntries(
            Object.entries(reducers).map(([key, value]) => {
                if (!reducersToUpdate.includes(key)) {
                    return [key, value];
                }

                return [key, {}];
            }),
        );

        // store.dispatch(toggleStoreUpdate(false));

        return updatedReducers;
    }

    return appReducer(state, action);
};

export default rootReducer;
