import { application_type } from 'constants/api';

export const getCurrentUser = () => {
    let user = null;

    try {
        user =
            localStorage.getItem(`${ application_type }_user`) != null
                ? JSON.parse(localStorage.getItem(`${ application_type }_user`))
                : null;
    } catch (error) {
        user = null;
    }

    return user;
};

export const setCurrentUser = (user) => {
    try {
        localStorage.setItem(`${ application_type }_user`, JSON.stringify(user));
    } catch (error) {}

    return user;
};

export const deleteCurrentUser = () => {
    try {
        localStorage.removeItem(`${ application_type }_user`);
    } catch (error) {}
};
