import * as types from './live.types';

const initState = {
    levelup:      null,
    levelupError: null,
};

export function liveReducer(state = initState, action) {
    const { type, levelup, levelupError } = action;

    switch (type) {
            case types.LIVE__ON_LEVELUP_EVENT: {
                return {
                    ...state,
                    levelup,
                    levelupError,
                };
            }

            case types.LIVE__ON_LEVELUP_EVENT_ERROR: {
                return {
                    ...state,
                    levelup,
                    levelupError,
                };
            }

            default:
                return state;
    }
}
