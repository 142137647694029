import * as t from './types';

const initialState = {
    total: 0,

    history:          null,
    historyIsLoading: false,
    historyError:     null,
};

export default (state = initialState, action) => {
    switch (action.type) {
            case t.SET_POINT:
                return {
                    ...state,
                    total: action.payload,
                };

            case t.POINTS_GET_HISTORY_REQUEST: {
                return {
                    ...state,
                    historyIsLoading: true,
                };
            }

            case t.POINTS_GET_HISTORY_ERROR: {
                return {
                    ...state,
                    history:          action.history,
                    historyError:     action.error,
                    historyIsLoading: false,
                };
            }

            case t.POINTS_GET_HISTORY_SUCCESS: {
                return {
                    ...state,
                    history:          action.history,
                    historyError:     action.error,
                    historyIsLoading: false,
                };
            }

            default:
                return state;
    }
};
