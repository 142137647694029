import * as t from "./types";


const initState = "fr";


export default (state = initState, action) => {
    switch (action.type) {
    case t.SET_LOCALE:
        return action.payload;
    default:
        return state;
    }
}
