export const TAGS_GET_ALL_REQUEST = 'TAGS_GET_ALL_REQUEST';
export const TAGS_GET_ALL_SUCCESS = 'TAGS_GET_ALL_SUCCESS';
export const TAGS_GET_ALL_ERROR = 'TAGS_GET_ALL_ERROR';

export const TAGS_GET_ALL_SELECTED_REQUEST = 'TAGS_GET_ALL_SELECTED_REQUEST';
export const TAGS_GET_ALL_SELECTED_SUCCESS = 'TAGS_GET_ALL_SELECTED_SUCCESS';
export const TAGS_GET_ALL_SELECTED_ERROR = 'TAGS_GET_ALL_SELECTED_ERROR';

export const TAGS_UPDATE_SELECTED_REQUEST = 'TAGS_UPDATE_SELECTED_REQUEST';
export const TAGS_UPDATE_SELECTED_SUCCESS = 'TAGS_UPDATE_SELECTED_SUCCESS';
export const TAGS_UPDATE_SELECTED_ERROR = 'TAGS_UPDATE_SELECTED_ERROR';
