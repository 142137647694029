import * as types from './tag.types';

const initState = {
    data:          null,
    dataError:     null,
    dataIsLoading: false,

    selected:          null,
    selectedError:     null,
    selectedIsLoading: false,

    isUpdated:          false,
    isUpdatedError:     false,
    isUpdatedIsLoading: false,
};

export function tagReducer(state = initState, action) {
    const { type, ...rest } = action;

    switch (type) {
        // get all
            case types.TAGS_GET_ALL_REQUEST: {
                return {
                    ...state,
                    isUpdated:     false,
                    dataIsLoading: true,
                };
            }

            case types.TAGS_GET_ALL_SUCCESS:

            case types.TAGS_GET_ALL_ERROR: {
                return {
                    ...state,
                    data:          rest.data,
                    dataError:     rest.error,
                    dataIsLoading: false,
                };
            }

            // get selected
            case types.TAGS_GET_ALL_SELECTED_REQUEST: {
                return {
                    ...state,
                    isUpdated:         false,
                    selectedIsLoading: true,
                };
            }

            case types.TAGS_GET_ALL_SELECTED_SUCCESS:

            case types.TAGS_GET_ALL_SELECTED_ERROR: {
                return {
                    ...state,
                    selected:          rest.selected,
                    selectedError:     rest.error,
                    selectedIsLoading: false,
                };
            }

            // updated selected
            case types.TAGS_UPDATE_SELECTED_REQUEST: {
                return {
                    ...state,
                    isUpdated:          false,
                    isUpdatedIsLoading: true,
                };
            }

            case types.TAGS_UPDATE_SELECTED_SUCCESS:

            case types.TAGS_UPDATE_SELECTED_ERROR: {
                return {
                    ...state,
                    isUpdated:          rest.isUpdated,
                    isUpdatedError:     rest.error,
                    isUpdatedIsLoading: false,
                };
            }

            default:
                return state;
    }
}
