import * as types from './wallet.types';

const initState = {
    wallet:          [],
    walletError:     null,
    walletIsLoading: false,

    history:          null,
    historyError:     null,
    historyIsLoading: false,

    expired:          [],
    expiredError:     null,
    expiredIsLoading: false,

    itemHistory:          [],
    itemHistoryError:     null,
    itemHistoryIsLoading: false,
};

// eslint-disable-next-line default-param-last
export function walletReducer(state = initState, action) {
    const { type, ...rest } = action;

    // eslint-disable-next-line
    switch (type) {

            // wallet items
            case types.WALLET_GET_ALL_REQUEST: {
                return {
                    ...state,
                    walletIsLoading: true,
                };
            }

            case types.WALLET_GET_ALL_SUCCESS: {
                return {
                    ...state,
                    wallet:          rest.wallet,
                    walletError:     rest.error,
                    walletIsLoading: false,
                };
            }

            case types.WALLET_GET_ALL_ERROR: {
                return {
                    ...state,
                    wallet:          rest.wallet,
                    walletError:     rest.error,
                    walletIsLoading: false,
                };
            }

            // transaction history
            case types.WALLET_GET_TRANSACTION_HISTORY_REQUEST: {
                return {
                    ...state,
                    historyIsLoading: true,
                };
            }

            case types.WALLET_GET_TRANSACTION_HISTORY_SUCCESS: {
                return {
                    ...state,
                    history:          rest.history,
                    historyError:     rest.error,
                    historyIsLoading: false,
                };
            }

            case types.WALLET_GET_TRANSACTION_HISTORY_ERROR: {
                return {
                    ...state,
                    history:          rest.history,
                    historyError:     rest.error,
                    historyIsLoading: false,
                };
            }

            // expired wallet items
            case types.WALLET_GET_EXPIRED_REQUEST: {
                return {
                    ...state,
                    expiredIsLoading: true,
                };
            }

            case types.WALLET_GET_EXPIRED_SUCCESS: {
                return {
                    ...state,
                    expired:          rest.expired,
                    expiredError:     rest.error,
                    expiredIsLoading: false,
                };
            }

            case types.WALLET_GET_EXPIRED_ERROR: {
                return {
                    ...state,
                    expired:          rest.expired,
                    expiredError:     rest.error,
                    expiredIsLoading: false,
                };
            }

            // item history
            case types.WALLET_GET_WALLET_ITEM_HISTORY_REQUEST: {
                return {
                    ...state,
                    itemHistoryIsLoading: true,
                };
            }

            case types.WALLET_GET_WALLET_ITEM_HISTORY_SUCCESS: {
                return {
                    ...state,
                    itemHistory:          rest.history,
                    itemHistoryError:     rest.error,
                    itemHistoryIsLoading: false,
                };
            }

            case types.WALLET_GET_WALLET_ITEM_HISTORY_ERROR: {
                return {
                    ...state,
                    itemHistory:          rest.history,
                    itemHistoryError:     rest.error,
                    itemHistoryIsLoading: false,
                };
            }

            default:
                return state;
    }
}
