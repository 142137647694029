import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';

const persistConfig = {
    key: 'root',
    storage,

    // prevent persisting for the following reducer names
    // blacklist: ['steps', 'loginProcess', 'user', 'profileReducer', 'levelsReducer', 'referralReducer', 'shopReducer'],
    whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer:    persistedReducer,
    devTools:   process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
