import isEmpty from 'lodash/isEmpty';

import TaooServerClient from 'http/axios';
import { deleteCurrentUser, getCurrentUser, setCurrentUser as setCurrentUserST } from 'services/utils/currentUser';

import { persistor } from '..';
import * as t from './types';

export const setUser = (user = {}) => ({
    type:    t.SET_USER,
    payload: user,
});

export const setCurrentUser =
    (user = {}) => (dispatch) => {
        if (isEmpty(user)) {
            delete TaooServerClient.defaults.headers.common.Authorization;

            if (getCurrentUser(user)) {
                deleteCurrentUser();
            }

            persistor.purge();
        } else {
            TaooServerClient.defaults.headers.Authorization = `Bearer ${ user.token }`;
            setCurrentUserST(user);
        }

        dispatch(setUser(user));
    };

export const setPin = () => (dispatch) => {
    const user = getCurrentUser(user);

    if (user) {
        user.pin = null;
        user.hasPin = true;

        dispatch(setUser(user));
        setCurrentUserST(user);
    }

    dispatch({
        type: t.SET_HAS_PIN,
    });
};

export const setOnBoarded = () => (dispatch) => {
    const user = getCurrentUser();
    
    if (user) {
        user.onboarded = true;

        setCurrentUserST(user);
    }

    dispatch({
        type: t.SET_ON_BOARDING_STEP,
    });
};

export const updateTooltipKey = (key, value) => (dispatch) => {
    const currentUser = getCurrentUser();

    const newUser = {
        ...currentUser,
        tooltips: {
            ...currentUser.tooltips,
            [key]: value,
        },
    };

    setCurrentUserST(newUser);

    dispatch({
        type:    t.UPDATE_TOOLTIP_KEY,
        payload: newUser,
    });
};
