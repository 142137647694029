export const PROFILE_GET_REQUEST = 'PROFILE_GET_REQUEST';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_ERROR = 'PROFILE_GET_ERROR';

export const PROFILE_GET_INFO_REQUEST = 'PROFILE_GET_INFO_REQUEST';
export const PROFILE_GET_INFO_SUCCESS = 'PROFILE_GET_INFO_SUCCESS';
export const PROFILE_GET_INFO_ERROR = 'PROFILE_GET_INFO_ERROR';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';

export const PROFILE_UPDATE_AVATAR_REQUEST = 'PROFILE_UPDATE_AVATAR_REQUEST';
export const PROFILE_UPDATE_AVATAR_SUCCESS = 'PROFILE_UPDATE_AVATAR_SUCCESS';
export const PROFILE_UPDATE_AVATAR_ERROR = 'PROFILE_UPDATE_AVATAR_ERROR';
