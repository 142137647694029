import axios from 'axios';
import { Config } from 'config/index';
import isEmpty from 'lodash/isEmpty';
import { createIntl } from 'react-intl';

import { customToast } from 'components/widgets/toast/useToast';
import { localeToLanguageMapper } from 'i18n/index';
import { capitalizeFirstLetter } from 'services/conversion';
import store from 'store/index';
import { setCurrentUser } from 'store/user/action';

import { handleResponseError } from './response';

const TaooServerClient = axios.create({
    baseURL: `${ Config.domain }/api/`,
});
const { locale } = store.getState();
const messages = localeToLanguageMapper[locale];
const intl = createIntl({ locale, messages });
const ignoredMessages = ['ACTION_ERROR', 'USER_EXIST', 'USER_SINGLE_SIGN_IN'];

export const generateNewCancelTokenSource = () => axios.CancelToken.source();
export const isRequestCanceled = (error) => axios.isCancel(error);

TaooServerClient.interceptors.response.use(
    (response) => {
        if (response && 'error' in response.data && !isEmpty(response.data.error)) {
            if (ignoredMessages.includes(response.data.message)) {
                return response;
            }

            const errorMessage = response.data?.message || response.data.error;

            const formattedMessage = intl.formatMessage({ id: errorMessage });

            customToast.error(formattedMessage, { toastId: errorMessage });
        }

        return response;
    },
    (error) => {
        if (isRequestCanceled(error)) {
            return;
        }

        if (error.response?.status === 401) {
            store.dispatch(setCurrentUser({}));

            return error;
        }

        if (error.response?.status === 400) {
            const errorMessage = error.response.data?.error || 'RUNTIME_ERROR';

            const url = error?.response?.config?.url ? error.response.config.url.split('/') : null;
            const errorFrom = url ? url[url.length - 1] : '';

            // const formattedMessage = intl.formatMessage({ id: errorMessage }, { errorFrom });
            const formattedMessage = intl.formatMessage({ id: errorMessage });
            const message = capitalizeFirstLetter(formattedMessage);

            customToast.error(message, { toastId: errorMessage });
        }

        const errorMessage = handleResponseError(error);

        return errorMessage;
    },
);

export default TaooServerClient;
