import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import OtpInput from 'components/form/otp-input';
import Loader from 'components/loaders/loader';
import { application_type, endpoints } from 'constants/api';
import { LoaderInButtonFillColor } from 'constants/defaults';
import { ErrorMessages } from 'constants/index';
import TaooServerClient from 'http/axios';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { setOtp } from 'store/login-process/action';
import { setCurrentUser } from 'store/user/action';
import { getStorageItem, setStorageItem } from 'services/storage';

import styles from './index.module.scss';

function CheckPin({
    path, lang, setCurrentUser, loginProcess, isReset = false,
}) {
    const [countdownApi, setCountdownApi] = useState(null);
    const [countdown, setCountdown] = useState(Date.now() + 1000 * 60);
    const [enableResendCode, setEnableResendCode] = useState(false);
    const [resendIsLoading, setResendIsLoading] = useState(false);

    const intl = useIntl();
    const navigate = useNavigate();

    const [otpValue, setotpValue] = useState('');
    const onChange = (value) => setotpValue(value);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const challengeType = getStorageItem('sign-up-challenge-type');

    useEffect(() => {
        const { email, password } = loginProcess;

        if (!isReset) {
            const message = getStorageItem('sign-up-message');

            if (isEmpty(email) && message !== ErrorMessages.auth_process.USER_SINGLE_SIGN_IN && isEmpty(password)) {
                navigate(All_PATHS_NAMESPACES[lang].home.path);
            }
        } else if (isEmpty(email)) {
            navigate(All_PATHS_NAMESPACES[lang].home.path);
        }
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleSendOtp = () => {
        if (!enableResendCode | resendIsLoading) {
            return;
        }

        setResendIsLoading(true);

        const { email: login } = loginProcess;

        TaooServerClient.post(endpoints.auth.resendOtp, {
            login,
            action: path === All_PATHS_NAMESPACES[lang].home.path ? 'VERIFICATION' : 'RESET_PASSWORD',
        })
                .then((result) => {
                    if(!result || !result?.data) {
                        throw new Error('API Error!');
                    }

                    if (path === All_PATHS_NAMESPACES[lang].home.path){
                        setStorageItem('sign-up-challenge', result.data?.challenge ?? '');
                    }
                    if(path === All_PATHS_NAMESPACES[lang].reset_password_new_password.path) {
                        setStorageItem('reset-password-challenge', result.data?.challenge ?? '');
                    }
                })
                .catch((err) => {
                    console.error(err.response);
                    setResendIsLoading(false);
                })
                .finally(() => {
                    setResendIsLoading(false);
                    setCountdown(Date.now() + 1000 * 60);
                    setEnableResendCode(false);
                    countdownApi.start();
                });
    };

    const handleLogin = async (login, password, challenge = null) => {
        try {
            const res = await TaooServerClient.post(endpoints.auth.login, {
                login,
                password,
                challenge,
                type:  'CLIENT',
            });
    
            const success = res.data?.success ?? true;
            const message = res.data?.message ?? '';

            if(!success && message === ErrorMessages.auth_process.USER_UNVERIFIED) {
                if(res.data?.challenge) {
                    setStorageItem('sign-up-challenge', res.data?.challenge);
                }
    
                setError(
                    intl.formatMessage({
                        id: 'otp.could-not-verify',
                    }),
                );
                
                return;
            }
            if (!success && message === ErrorMessages.auth_process.USER_AUTH_FAILED) {
                setError(
                    intl.formatMessage({
                        id: ErrorMessages.auth_process.USER_AUTH_FAILED,
                    }),
                );
                navigate(All_PATHS_NAMESPACES[lang].login.path);
    
                return;
            }
    
            const {
                id, firstName, lastName, extra, token, email,
            } = res.data;
            const user = {
                id,
                login:          email,
                firstName,
                lastName,
                token,
                hasPin:         true,
                pin:            extra.pinChallenge,
                onboarded:      extra.onboarded,
                onboardingStep: extra.onboardingStep,
                totalPoints:    extra.totalPoints,
                tooltips:       extra.tooltips,
            };
    
            setCurrentUser(user);
        } catch(e) {
            console.error(e)
        }
    }

    const handleCheckPin = async () => {
        setIsLoading(true);
        setError(null);

        const { email, password } = loginProcess;

        if (path === All_PATHS_NAMESPACES[lang].home.path) {
            const message = getStorageItem('sign-up-message');
            const challenge = getStorageItem('sign-up-challenge');

            if(message === ErrorMessages.auth_process.USER_SINGLE_SIGN_IN){
                await handleLogin(email, otpValue,challenge);

                return;
            }

            TaooServerClient.post(endpoints.auth.otp, {
                otp:    otpValue,
                challenge,
                action: 'VERIFICATION',
            })
                    .then((res) => {
                        if(!res?.data || res?.data?.success === false || res?.data?.error === 'RUNTIME_ERROR') {
                            return;
                        }

                        const { status = null } = res.data;

                        return status;
                    })
                    .then((status) => {
                        if (status !== 'ACTIVE') {
                            const err = new Error('ACCOUNT_COULD_NOT_ACTIVATE');

                            err.status = 500;

                            throw err;
                        }

                        return handleLogin(email, password)
                    })
                    .catch((err) => {
                        const message = err?.message ?? err;

                        setError(intl.formatMessage({ id: message }));
                    })
                    .finally(() => setIsLoading(false));

            return;
        }

        if (path === All_PATHS_NAMESPACES[lang].reset_password_new_password.path) {
            const challenge = getStorageItem('reset-password-challenge');
            TaooServerClient.post(endpoints.auth.otp, {
                otp:    otpValue,
                challenge,
            })
                    .then((res) => {
                        if(!res?.data || res?.data?.success === false || res?.data?.error === 'RUNTIME_ERROR') {
                            return;
                        }

                        const { status = null, challenge } = res.data;

                        setIsLoading(false);


                        if (status === 'ACTIVE') {
                            navigate(path, { state: { challenge, email } });
                        }

                        return status;
                    })
                    .catch((err) => {
                        const message = err?.message ?? err;

                        setError(intl.formatMessage({ id: message }));
                        setIsLoading(false);
                    });
        }
    };

    const setRef = (countdown) => {
        if (countdown) {
            setCountdownApi(countdown.getApi());
        }
    };

    return (
        <main className="flex-grow-1 flex-column flex-grow-1 form-container">
            <div onClick={handleBack} className={`${ styles.svg_wrapper } svg-switch-colored-path mb-1`}>
                <svg width="42" height="25" viewBox="0 0 42 25" fill="none">
                    <path
                        d="M0.967425 11.9029C0.662375 12.2748 0.711287 12.7264 0.91072 13.023L0.91072 13.041L0.979514 13.1134L11.3024 23.974L11.3023 23.974L11.3039 23.9756C11.6478 24.3308 12.2323 24.3392 12.574 24.0122L12.5741 24.0122C12.9139 23.6868 12.9432 23.1004 12.613 22.7512L12.6126 22.7507L3.71919 13.3903L40.3547 13.3903C40.8468 13.3903 41.25 12.9946 41.25 12.5013C41.25 12.008 40.8468 11.6122 40.3547 11.6122L3.71919 11.6122L12.6126 2.25185L12.6129 2.25143C12.9408 1.90474 12.9209 1.31205 12.5711 0.987696C12.224 0.665795 11.6723 0.664494 11.3082 1.02264L11.3081 1.02254L11.3024 1.02863L0.979514 11.8892L0.97322 11.8958L0.967425 11.9029Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                    />
                </svg>
            </div>
            <h3 className="mb-3">
                <FormattedMessage id="checkpin.security_code" />
            </h3>
            <p>
                <FormattedMessage id="checkpin.enter_otp_msg" />
            </p>
            <OtpInput value={otpValue} valueLength={4} onChange={onChange} />
            {error && <p className="error color-red mt-3">{error}</p>}
            <button disabled={isLoading} type="button" onClick={handleCheckPin} className="my-4 button type-submit">
                {isLoading ? <Loader fill={LoaderInButtonFillColor} /> : <FormattedMessage id="checkpin.submit_form" />}
            </button>

            <p className="info">
                <FormattedMessage id="checkpin.check_inbox_msg" />
            </p>

            <div className={styles.resendCode}>
                <span
                    aria-disabled={!enableResendCode || resendIsLoading}
                    className={`${ styles.inbox } ${ !enableResendCode || resendIsLoading ? '' : styles.active }`}
                    onClick={handleSendOtp}
                >
                    <FormattedMessage id="checkpin.check_inbox_msg_resend" />
                </span>

                {resendIsLoading && (
                    <Loader
                        wrapperStyle={{
                            width:       '1.3rem',
                            height:      '1.3rem',
                            margin:      '0',
                            marginRight: '0.5rem',
                        }}
                    />
                )}

                {countdown > 0 && (
                    <Countdown
                        ref={setRef}
                        className={styles.countdown}
                        date={countdown}
                        autoStart
                        renderer={renderer}
                        onComplete={() => {
                            setEnableResendCode(true);
                            setCountdown(0);
                        }}
                    />
                )}
            </div>
        </main>
    );
}
const renderer = ({ minutes, seconds }) => (
    <span className={styles.countdown}>
        {minutes}:{seconds}
    </span>
);

const mapStateToProps = (state) => ({
    lang:         state.locale,
    loginProcess: state.loginProcess,
});

export default connect(mapStateToProps, { setOtp, setCurrentUser })(CheckPin);
