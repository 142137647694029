import * as t from './types';

const user = {};

export default (state = user, action) => {
    switch (action.type) {
            case t.SET_USER:
                return action.payload;

            case t.SET_HAS_PIN:
                return { ...state, hasPin: true };

            case t.SET_ON_BOARDING_STEP:
                return { ...state, onboarded: true };

            case t.UPDATE_TOOLTIP_KEY:
                return action.payload;

            default:
                return state;
    }
};
