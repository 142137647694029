import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

/**
 *
 * @param {AxiosResponse<any, any>} response
 * @returns
 */
export const handleResponseError = (response) => {
    if ('error' in response) {
        return response.error;
    }

    let axiosError = response;

    if (axios.isAxiosError(axiosError)) {
        axiosError = axiosError.response?.data;
    }

    if (response?.data && !isEmpty(response.data?.error)) {
        axiosError = response.data;
    }

    if (typeof axiosError === 'object' && 'error' in axiosError && !axiosError?.success) {
        return axiosError?.message ?? axiosError.error;
    }
};
